export class KiwiMonsterFund {
  Id: number;
  AnnualManagementFee: number;
  AverageFiveYearReturnNet: number | null;
  AverageFiveYearReturnTaxGross: number;
  FundClassificationCode: string;
  FundDescription: string;
  FundName: string;
  FundNumber: string;
  FundStartDate: string;
  FundStatus: string;
  FundToOpen: number;
  FundTotalCurrencyCode: string;
  FundTotalValue: number;
  IsDeleted: boolean;
  MarketIndexDescription: string;
  MarketIndexFiveYearReturnGross: number;
  MarketIndexPastYearReturnGross: number;
  MarketIndexSinceInceptionReturnGross: number;
  NumberOfInvestors: number;
  OfferNumber: string;
  OtherFeesAndCosts: number;
  PastYearReturnNet: number | null;
  PastYearReturnTaxGross: number;
  PerformanceBasedFees: number;
  PerformanceSinceInceptionReturnNet: number;
  QmCalcFeeInPercentage: number;
  QmCalcReturnInPercentage: number;
  QmRiskProfileCode: string | null;
  RiskRewardIndicatorCode: number;
  SchemeName: string;
  SchemeNumber: string;
  SchemeStatus: string;
  TotalAmountOfOtherCharges: number;
  TotalAnnualFundCharge: number;
  WithdrawalFeesPercentage: number;
  IsLifeCycleUnder50: boolean;
  IsLifeCycleOver50: boolean;
  // for UI
  IsSelectedAsProspective: boolean;
  
  static updateFundType(fund: KiwiMonsterFund): string {
    if (fund.IsLifeCycleOver50 || fund.IsLifeCycleUnder50) {
      return 'Life cycle option';
    }
    return '';
  }
}