import { Component, OnInit } from '@angular/core';
import { BaseInfoPageComponent } from '../../shared/base-info-page.component';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { InfoPage } from 'src/app/models/info-page';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { UserService } from 'src/app/service/user.service';
import { ConfirmMessageDialogService } from '../../shared/confirm-message-dialog.service';

@Component({
  selector: 'app-kiwimonster-how-it-works',
  templateUrl: '../../shared/base-info-page.component.html',
  styleUrls: ['../../shared/base-info-page.component.scss']
})
export class KiwiMonsterHowItWorksComponent extends BaseInfoPageComponent implements OnInit {

  constructor (
    public loginService: LoginService,
    public userService: UserService,
    public http: HttpClient,
    public sharedFunction: SharedFunctionService,
    public router: Router,
    public confirmDialog: ConfirmMessageDialogService
  ) {
    super(loginService, userService, http, sharedFunction, router, confirmDialog);
  }

  ngOnInit(): void {
    this.loginService.checkLoginStatus();

    let infoPage = new InfoPage();
    infoPage.Key = 'kiwimonster-how-it-works';
    infoPage.Title = 'Kiwimonster Appendix of Things';

    this.loadLocalTemplat(infoPage);
  }

}
